import { useAuth } from '@/lib/auth';
import classNames from '@/util/classname';
import useHover from '@/util/useHover';
import {
    BriefcaseIcon,
    HomeIcon,
    SparklesIcon,
    LightningBoltIcon,
    PresentationChartBarIcon,
} from '@heroicons/react/solid';
import Link from 'next/link';
import { useRouter } from 'next/router';
import type { SVGProps } from 'react';
import { AuthProps } from 'types/AuthProps';
import { IconProps } from './icons';

type NavItemProps = {
    text: string;
    href: string;
    Icon: (props: SVGProps<SVGSVGElement>) => JSX.Element;
};

const mainNavigation: NavigationProps[] = [
    {
        text: 'Home',
        href: '/',
        Icon: HomeIcon,
    },
    {
        text: 'Find',
        href: '/find',
        Icon: SparklesIcon,
    },
    {
        text: 'Connect',
        href: '/connect',
        Icon: LightningBoltIcon,
    },
    {
        text: 'Sell',
        href: '/sell',
        Icon: BriefcaseIcon,
    },
    {
        text: 'Reports',
        href: '/reports',
        Icon: PresentationChartBarIcon,
        isAdmin: true,
    },
];

export function adminNavigationFilter(
    auth: AuthProps | undefined = undefined,
    navigation: NavigationProps[] = mainNavigation
) {
    if (!auth?.user?.isAdmin) {
        return navigation.filter((navItem) => !navItem.isAdmin);
    }

    return navigation;
}

export type NavigationProps = {
    text: string;
    href: string;
    isAdmin?: boolean;
    Icon: (props: IconProps) => JSX.Element;
    bgStyles?: string;
    linkStyles?: string;
    iconBg?: string;
};

function NavigationButton({ navItem }: { navItem: NavItemProps }) {
    const [hoverRef, isHovered] = useHover();
    const router = useRouter();

    return (
        <div ref={hoverRef as React.RefObject<HTMLDivElement>}>
            <Link href={navItem.href}>
                <a
                    title={navItem.text}
                    className={classNames(
                        navItem.href === router.pathname
                            ? 'bg-gray-100 text-gray-900'
                            : 'text-gray-500 hover:bg-gray-100 hover:text-gray-900',
                        'flex w-full flex-grow items-center justify-center rounded-lg py-3'
                    )}
                >
                    <navItem.Icon className="h-6 w-6" />
                </a>
            </Link>
            <div className="relative">
                {isHovered ? (
                    <div className="absolute left-1/2 mx-auto mt-1 -translate-x-1/2 rounded-md bg-gray-700 p-2 text-xs text-white shadow-xl">
                        <span>{navItem.text}</span>
                    </div>
                ) : null}
            </div>
        </div>
    );
}

export default function Navigation() {
    const auth = useAuth();

    if (!auth) {
        return (
            <nav>
                <ul className="m-auto grid max-w-prose grid-cols-4 gap-4 px-4">
                    {adminNavigationFilter().map((navItem) => (
                        <li key={navItem.href}>
                            <NavigationButton navItem={navItem} />
                        </li>
                    ))}
                </ul>
            </nav>
        );
    }

    return (
        <nav>
            <ul
                className={`m-auto grid max-w-prose grid-cols-${
                    adminNavigationFilter(auth).length
                } gap-4 px-4`}
            >
                {adminNavigationFilter(auth).map((navItem) => (
                    <li key={navItem.href}>
                        <NavigationButton navItem={navItem} />
                    </li>
                ))}
            </ul>
        </nav>
    );
}
